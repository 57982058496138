.flight-first-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flights-tabs{
    width: 23rem;
    display: flex;

}

.flights-tabs > span{
    width: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.flights-tabs > span > div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.flights-tabs> span div > span:first-child{
    border: .0001rem rgb(59, 57, 57) solid;
    width: .77rem;
    height: .77rem;
    border-radius: 100%;
    margin-right: .3rem;
}

.flight-second-box{
    display: grid;
    border: 1px solid rgb(66, 65, 65);
    border-radius: .5rem;
    /* justify-content: center; */
    /* align-items: center; */
    grid-template-columns: 4fr 0fr 4fr 3fr 3fr;
    /* height: 7rem; */
}

.flight-second-box h3{
    padding: 0px;
    margin: 0px;
    font-weight: bold
} 
.flight-second-box p{
    padding: 0px;
    margin: 0px;
    font-size: .9rem;
} 
.flight-second-box > div > div > p,  .flight-date-departure > div >p{
    font-size: medium;
    margin-bottom: .5rem;

} 

.flight-from, .flight-to, .flight-travellers{
    border-right: 1px solid rgb(66, 65, 65);
    cursor: pointer;
    
}
.flight-from > div, .flight-to >  div, .flight-travellers > div{
    padding: .5rem;
   
}
.flight-to >  div{
    padding-left: 1.5rem;
}

.flight-from:hover{
    background-color: #f2f5f6;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
.flight-travellers:hover{
    background-color: #f2f5f6;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.flight-to:hover, .flight-date-departure:hover, .flight-date-return:hover{
    background-color: #f2f5f6;
}


.airport-search-container{
    position: relative;
    top: -4.5rem;
    left: -.5rem;
    z-index: 10;
    width: 110%;
    cursor: default;
  
    
    

}

.airport-search-container-to{
    left: -1.5rem;
}

.airport-search-container > div{
    position: absolute;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    
}

.airport-search-container input{
    width: 100%;
    border: none;
    outline: none;
    text-decoration: none;
    font-weight: 900;
}

.airport-search-container > div > div{
    overflow-y: scroll;
    height: 30vh;
    padding: .5rem;   
}

.switch-button{
    position: relative;
}

.switch-button > div{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -1rem;
    top:35%;
    background-color: #fff;
    z-index: 9;
    cursor: pointer;
    
}

#input-box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    overflow-y: hidden;
    height: 2rem;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    padding: .5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.search-result-display{
    display: grid;
    gap: .7rem;
}
.search-result-display-title{
    color: rgb(110, 108, 108);
    font-weight: 600;

}
.search-result-display-item{
    cursor: pointer;
}
.search-result-display-city-iata{
    display: flex;
    justify-content: space-between;


}

.search-result-display-city-iata img{
    width: 1.5rem;
    height: 1.5rem;

}

#search-result-display-city{
    color: black;
    /* font-weight: 100; */
    /* font-size: .5rem; */
}
#search-result-display-airport{
    color: rgb(110, 108, 108);
    font-size: smaller;
}

#search-result-display-iata{
    color: rgb(110, 108, 108);
    font-weight: 800;

}

.flight-date{
    display: flex;
    justify-content: space-between;
   
}

.flight-date-departure, .flight-date-return{
    padding: .5rem;
    width: 100%;
    cursor: pointer;
}

.flight-date-departure{
    border-right: 1px solid black;
}

.airport-search-container-date{
    position: relative;
    top: -3.5rem;
    left: -100%;   
    z-index: 100; 
}

.airport-search-container-date > div > div{
    overflow-y: hidden;
    height: 53vh;
    width: 28vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}


.return-close{
    position: absolute;
    top: .5rem;
    right: 1rem;
    display: grid;
    justify-content: center;
    align-items: center;
}

.airport-search-container-travellers{
    position: relative;
    left: -153%;
}

.airport-search-container-travellers > div{
    right: 100%;
    left: -20%;
    width: 250%;
    padding: 2rem;
}

.airport-search-container-travellers > div div{
height: auto;
overflow-y: auto;

}

#flight-travellers-title{
    font-size: small;
    font-weight: 800;
}

#flight-travellers-subtitle{
    font-size: smaller;
    font-weight: 600;
    color: rgb(110, 108, 108);

}

#flight-travellers-numbers{
    box-shadow:0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    overflow-y: hidden;
    width: fit-content;
    padding: .3rem;
    border-radius: .2rem;
    margin-top: .4rem;
    
}

#flight-travellers-numbers span{
    padding: .3rem;
    padding-right: .5rem;
    padding-left: .5rem;
    margin: .3rem;
    border-radius: .2rem;
    font-size: smaller;
    cursor: pointer;
}
#flight-travellers-numbers span:hover{
    background-color: #f2f5f6;
}

.apply-button{
    border: none;
    padding: .5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background: #133957;
    border-radius: 2rem;
    color: #fff;
}


.flight-travellers-multi:hover{
    background-color: #fff;
    cursor: default;
}



.flight-third-box{
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.flights-tabs-third-box > span > div {
    border-radius: 0px;
}

/* .flights-tabs-third-box > span div > span:first-child{

    margin-right: 0px;
} */

.flights-tabs-third-box{
    width: auto;
    display: flex;
    font-size: .8rem;
}

.fare-type-item{
    justify-content: center;
    background-color: #f2f5f6;
    border: 1px white solid;
    white-space: nowrap;
}

.flights-tabs-third-box-text-wrapper p{
    padding: 0px;
    margin: 0px;
}
