.mobile-airports{
    padding: 1rem;
    position: absolute;
    width: 100%;
    top: 0px;
    background-color: #fff;  
    height: 100vh;
}



.mobile-airports i{
    color: black;
    font-size: 1.5rem;
    -webkit-text-stroke: .025rem;
    cursor: pointer;
}

.mobile-airports  input{
    width: 75vw;
    border: none;
    outline: none;
    text-decoration: none;
    font-weight: 900;
    padding: 0px;
}

