.mobile-travellers{
    background-color: #fff;
    position: absolute;
    width: 100%;
    bottom: 0px;
}
.mobile-travellers p{
    margin: 0px;
}
.mobile-travellers-navigation{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.mobile-travellers-common{
    padding: 1rem;
}
.mobile-travellers-common > p{
    font-size: .9rem;
    font-weight: 700;
}
.mobile-travellers-no{
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mobile-travellers-no > div > p:first-child{
    color: #000;
    font-size: 1.3rem;
    font-weight: 800;
}

.mobile-travellers-no > div > p:last-child{
    font-size: .8rem;
}

.mobile-travellers-no > div:last-child{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    padding: .2rem;
    display: flex;
    justify-content: space-between;
    gap: .1rem;
    font-size: 1.5rem;
    font-weight: 800;
    border-radius: .3rem;
    color: #000;
}

.mobile-travellers-no > div:last-child > span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
}

.mobile-travellers-no > div:last-child > span:first-child{
    color: rgba(0, 0, 0, 0.5);
}

.mobile-travellers-no > div:last-child > span.active{
    background-color: #fff;
    color: #000;
   
}

#mobile-travellers-class{
    padding-top: 1rem;
    margin-left: -.5rem;
}

.search-button-container-mobile-travellers{
    padding: 1rem;
}