@media (min-width:1120px)  { 
    .search-main-conatiner-mobile{
        display: none;
    }
}

.search-main-conatiner-mobile{
    position: relative;
    margin-top: 30px;
}

.search-main-conatiner-mobile-navigation{

    padding: .5rem;
    font-weight: bold;
    color: black;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.search-main-conatiner-mobile-navigation i{
    color: black;
    font-size: 1.5rem;
    -webkit-text-stroke: .025rem;
    cursor: pointer;
}

.search-main-conatiner-mobile-content{
    padding: 1rem;

}

.search-main-conatiner-mobile-content-trip-type{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    padding: .5rem;
    cursor: pointer;
}

.search-main-conatiner-mobile-content-trip-type > span{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: .2rem;
    border-radius: .3rem;
}

.search-main-conatiner-mobile-content-trip-type > span{
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
}

.search-main-conatiner-mobile-content-trip-type > span:hover{
    background-color: #133957;
    color:#fff;
}

.search-main-conatiner-mobile-content-trip-type > span.active{
    background-color: #133957;
    color: #fff;
}

/* common*/
.search-main-conatiner-mobile-content-common{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgb(247, 247, 247);
    padding: .5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-top: .5rem;
    margin-bottom: .5rem;
    line-height: 1rem;
}


.search-main-conatiner-mobile-content-common p:first-child{
    margin: 0px;
    font-size: .8rem;
    font-weight: 800;
}

.search-main-conatiner-mobile-content-common  strong{
    margin: 0px;
    font-size: 1rem;
    color: #000;
    font-weight: 800;
}

.search-main-conatiner-mobile-content-common  i{
    font-size: 1.3rem;
}

.search-main-conatiner-mobile-content-common p:last-child, .search-main-conatiner-mobile-common-airport-name{
    margin: 0px;
    font-size: .7rem;
    font-weight: 800;
    padding: 0px;

}

/* airport */
.search-main-conatiner-mobile-common-airport{

}
/* date */
.date-mobile > .search-main-conatiner-mobile-content-common{
    margin: 0px;
}

.date-mobile{
    display: flex;
    gap: 1rem;
}

/* fare */

.search-main-conatiner-mobile-content-fare-type > div{
    display: flex;
    gap: 1rem;
    cursor: pointer;
    margin-left: .5rem;
    /* overflow-x: auto; */
    text-wrap: nowrap;
    /* scrollbar-width: none;  */
    flex-wrap: wrap;
    
    
}

.search-main-conatiner-mobile-content-fare-type > p{
    padding: 0px;    
    font-size: .8rem;
    font-weight: 700;
}

.search-main-conatiner-mobile-content-fare-type > div > span{
    display: flex;
    justify-content: center;
    padding: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: .3rem;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
    font-size: .9rem;
    border: 1px solid #fff;
    margin: .1rem;
    
}


.search-main-conatiner-mobile-content-fare-type> div > span:hover{
    border-color: #f1a2a3;
    border: 1px solid #f1a2a3;
}

.search-main-conatiner-mobile-content-fare-type > div > span.active{
    background-color: #fff;
    border: 1px solid #e03a3c;
    font-weight: 800;
    color: #e03a3c;
}

/* search button */
#search-button-container-mobile{
    width: 100%;
    margin-top: 2rem;
}

#search-button-mobile{
    width: 100%;
    padding: 0px;
    border: none;
    background: #133957;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: .3rem;
    color: #fff;
    font-weight: 800;
    font-size: 1.2rem;
}

/* popups */
.mobile-popups{
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color:rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: 1000;
    height: -o-calc(100vh - 65px); /* opera */
    height: -webkit-calc(100vh - 65px); /* google, safari */
    height: -moz-calc(100vh - 65px); /* firefox */


}

/* #mobile-popups-contents{
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-color: #fff;   
    

} */




.switch-button-mobile{
    position: relative;
    
}

.switch-button-mobile > div{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1rem;
    top:-1.1rem;
    background-color: #fff;
    z-index: 9;
    cursor: pointer;
    transform: rotate(90deg);
    
    
}
