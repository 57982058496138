.search-main-container{
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 1rem;
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    

}

.search-tabs-navigation{
    display: flex;
    width: 80%;
    gap: 1rem;
    justify-content: center;
    position: absolute;
    top: -3rem;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    border-radius: .4rem;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);

}
#search-button-container{
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -1.8rem;
    border-radius: .4rem;
    
}
#search-button{
    padding: 0px;
    border: none;
    /*background: linear-gradient(to right,#e46b97, #e03a3c);*/
    background: #133957;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 2rem;
    color: #fff;
    font-weight: 800;
    font-size: 1.5rem;
}

.search-tabs-item{
    height: 5.5rem;
    width: 5rem;
    font-size: .9rem;
    text-align: center;
    cursor: pointer;


}
.search-tabs-item > p{
    margin-top: .4rem;
    line-height: 1.1rem;
}

.search-items-main-container{
    margin-top: 2.3rem;
    width: 100%;
}


@media (max-width:1120px)  { 
    .search-main-container{
        display: none;
    }
}



