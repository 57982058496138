.mobile-dates{
    background-color: #fff;
    position: absolute;
    width: 100%;
    top: 0px;
}
.mobile-dates p{
    margin: 0px;
}
.mobile-dates-navigation{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.mobile-dates-common{
    padding: 1rem;
}
.mobile-dates-common > p{
    font-size: .9rem;
    font-weight: 700;
}
.mobile-dates-no{
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mobile-dates-no > div > p:first-child{
    color: #000;
    font-size: 1.3rem;
    font-weight: 800;
}

.mobile-dates-no > div > p:last-child{
    font-size: .8rem;
}

.mobile-dates-no > div:last-child{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    padding: .2rem;
    display: flex;
    justify-content: space-between;
    gap: .1rem;
    font-size: 1.5rem;
    font-weight: 800;
    border-radius: .3rem;
    color: #000;
}

.mobile-dates-no > div:last-child > span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
}

.mobile-dates-no > div:last-child > span:first-child{
    color: rgba(0, 0, 0, 0.5);
}

.mobile-dates-no > div:last-child > span.active{
    background-color: #fff;
    color: #000;
   
}

#mobile-dates-class{
    padding-top: 1rem;
    margin-left: -.5rem;
}

.search-button-container-mobile-dates{
    padding: 1rem;
}

.calendar{
    border: none;
    width: 100%;
}

