.multicity-container-mobile > div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    gap: .5rem;

}

.multicity-container-mobile > div > div{
    margin-bottom: .5rem;
    margin-top: .5rem;
    width: 100%;
    border: 1px solid rgba(224,58,60, .4);
    border-radius: .3rem;
    padding: .5rem;
    line-height: 1rem;
    display: grid;
    align-items: center;
    /* height: 4rem; */
}
.multicity-container-mobile > div > div:last-child{
    width: 30px;
    padding: 0px;
    border: none;
}

.multicity-container-mobile > div > div > p:first-child{
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    color: black;
    font-size: 1rem;

}

.multicity-container-mobile > div > div > p:last-child{
    margin: 0px;
    padding: 0px;
    font-size: .8rem;
    color: black;
}

.multicity-container-mobile > div:first-child{
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: small;
    font-weight: 700;
    margin-top: 1rem;
    gap: 1rem;
}

.multicity-container-mobile > div:first-child span{
    width: 100%;
}