/**
* Template Name: Presento
* Template URL: https://bootstrapmade.com/presento-bootstrap-corporate-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #e03a3c;
  text-decoration: none;
}

a:hover {
  color: #e76668;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #e03a3c;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #e65d5f;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}

#header.header-scrolled {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 17px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;

}

#header .logo a {
  color: #111111;
}
.first_logo{
  color: #c31a1a!important;
}
.second_logo{
  color: #699d34;
}
#header .logo img {
  max-height: 70px;
  margin-left: 23px;
}
.flight_text p{
  color:#444444;
}
.flight_text i{
  color:#444444;
}
/*--------------------------------------------------------------
# Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  margin-left: 30px;
  background: #e03a3c;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}
.get-started-btn1 {
  margin-left: 30px;
  border-radius: 4px;
  white-space: nowrap;
  transition: 0.3s;
  display: inline-block;
}
.get-started-btn:hover {
  background: #111111;
  color: #fff;
}
.get-started-btn1 img{
  width: 100px;
  height: 69px;
}
.float1 {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 2px;
  bottom: 10px;
  background: #133957;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}
.float1:hover{
  color: #000;
}
@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
  .get-started-btn1 img{
    width: 135px;
    height: 70px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #133957;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #f65c19;
  background-color: transparent;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #f65c19;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #111111;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #111111;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #e03a3c;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #e03a3c;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 30vh;
  background: url("../public/images/hero-bg.jpg") top center no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 82px;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}

#hero h2 {
  color: #fff;
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #e03a3c;
  border: 2px solid #e03a3c;
}

#hero .btn-get-started:hover {
  background: transparent;
  border-color: #fff;
}

@media (max-width: 768px) {
  #hero {
    text-align: center;
    padding-top: 0px;
    height: 17vh;
  }

  #hero h1 {
    font-size: 28px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 40px 0;
  overflow: hidden;
  position: relative;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e03a3c;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

.section-bg {
  padding: 120px 0;
  color: #fff;
}

.section-bg:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
  padding: 140px 0 80px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs .page-header:before {
  content: "";
  background-color: rgba(14, 29, 52, 0.8);
  position: absolute;
  inset: 0;
}

.breadcrumbs .page-header h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs .page-header p {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs nav {
  background-color: #f3f6fc;
  padding: 20px 0;
}

.breadcrumbs nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-secondary);
}

.breadcrumbs nav ol a {
  color: var(--color-primary);
  transition: 0.3s;
}

.breadcrumbs nav ol a:hover {
  text-decoration: underline;
}

.breadcrumbs nav ol li+li {
  padding-left: 10px;
}

.breadcrumbs nav ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4278cc;
  content: "/";
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e03a3c;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e03a3c;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  opacity: 1;
  filter: none;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .container {
  position: relative;
  z-index: 10;
}

.about .content {
  padding: 30px 30px 30px 0;
}

.about .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.about .content p {
  margin-bottom: 30px;
}

.about .content .about-btn {
  padding: 8px 30px 9px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  border: 2px solid #e03a3c;
}

.about .content .about-btn i {
  font-size: 16px;
  padding-left: 5px;
}

.about .content .about-btn:hover {
  background: #e35052;
  background: #e03a3c;
}

.about .icon-boxes .icon-box {
  margin-top: 30px;
}

.about .icon-boxes .icon-box i {
  font-size: 40px;
  color: #e03a3c;
  margin-bottom: 10px;
}

.about .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.about .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

@media (max-width: 1200px) {
  .about .content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .about {
    text-align: center;
  }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 80px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.counts .count-box i {
  position: absolute;
  width: 54px;
  height: 54px;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #fff;
  color: #e03a3c;
  border-radius: 50px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 700;
  color: #111111;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/
.tabs .nav-tabs {
  border: 0;
}

.tabs .nav-link {
  border: 1px solid #b9b9b9;
  padding: 15px;
  transition: 0.3s;
  color: #111111;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabs .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.tabs .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.tabs .nav-link:hover {
  color: #e03a3c;
}

.tabs .nav-link.active {
  background: #e03a3c;
  color: #fff;
  border-color: #e03a3c;
}

@media (max-width: 768px) {
  .tabs .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .tabs .nav-link {
    padding: 15px;
  }

  .tabs .nav-link i {
    font-size: 24px;
  }
}

.tabs .tab-content {
  margin-top: 30px;
}

.tabs .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.tabs .tab-pane ul {
  list-style: none;
  padding: 0;
}

.tabs .tab-pane ul li {
  padding-bottom: 10px;
}

.tabs .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e03a3c;
}

.tabs .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  background: #252525;
  transition: 0.3s;
  position: relative;
}

.services .icon-box:hover {
  background: #2b2b2b;
}

.services .icon-box i {
  float: left;
  color: #e03a3c;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #fff;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  text-decoration: underline;
}

.services .icon-box .icon-box:hover h4 a {
  color: #e03a3c;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #e03a3c;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(17, 17, 17, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(17, 17, 17, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #fff;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e03a3c;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e03a3c;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(17, 17, 17, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 30px 15px;
  min-height: 200px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
  border-radius: 15px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 25px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #fceaea;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 30px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e03a3c;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e03a3c;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #2b2b2b;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.pricing .box h4 {
  font-size: 42px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul i {
  color: #e03a3c;
  font-size: 18px;
  padding-right: 4px;
}

.pricing .box ul .na {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px 12px 40px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.pricing .box .btn-buy:hover {
  border-color: #fff;
}

.pricing .featured {
  background: #e03a3c;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: #e03a3c;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-right: 25px;
  cursor: pointer;
}

.faq .faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #e03a3c;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  position: relative;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #111111;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: #e03a3c;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.team .member .social a:hover {
  background: #111111;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #111111;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.contact .info-box i {
  font-size: 32px;
  color: #133957;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f8d4d5;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 25px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #111111;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #133957;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #133957;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #111111;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #e03a3c;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #777777;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #e03a3c;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #e35052;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #111111;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #5e5e5e;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #1e1e1e;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #e03a3c;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #111111;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(17, 17, 17, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #e03a3c;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #111111;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #2b2b2b;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #ee9293;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #ee9293;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #111111;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #1e1e1e;
}

.blog .blog-pagination {
  color: #444444;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #111111;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #e03a3c;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #111111;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #e03a3c;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #e34c4d;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #111111;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #e03a3c;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #111111;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #e03a3c;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #515151;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #c4c4c4;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #e03a3c;
  background: #e03a3c;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #fff;
  font-size: 14px;
  background: #111111;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #1b1b1b;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #e03a3c;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: #e03a3c;
  bottom: 0;
  left: 0;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: white;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #aaaaaa;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #fff;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  text-align: left;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #133957;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: linear-gradient(90deg, #e85f20, #ff8a52);
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #2b2b2b;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #e03a3c;
  color: #fff;
  text-decoration: none;
}

/*Home Search*/

#second{
	margin-top: 11%;
    border: 1px solid;
    box-shadow: 5px 5px 5px 3px #131313;
    background-color: rgba(90,0,0,0.3);
}
#flightsButton{
	background-color: rgba(150,0,0,0.7);
	padding: 9.8px;
	border: none;
    width: 170px;
}

#tickButton{
	background-color: rgba(150,0,0,0.7);
	padding: 9.8px;
	border: none;
    width: 170px;
    margin-bottom: 5px;
    margin-left: 42.5%;
}

#fsButton{
	background-color: rgba(150,0,0,0.7);
	padding: 9.8px;
	border: none;
    width: 170px;
}
#content{
	text-align: center;
	padding-top: 10%;
	text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
				 0px 8px 13px rgba(0,0,0,0.1),
				 0px 18px 23px rgba(0,0,0,0.1);
}
.btn-group-lg>.btn, .btn-lg {
	padding: 8px 40px;
}
.tabshead{
	font-weight: bold;
	font-size: 1.5em;
	color: white;
}

.tab-content {
	color: white;
}

#second{
	margin-top: 11%;
    border: 1px solid;
    box-shadow: 5px 5px 5px 3px #131313;
    background-color: rgba(90,0,0,0.3);
}

.drop{
	color: black;
	width: 30%;
	height: 40px;
	font-size: 1.3em;
	margin-bottom: 20px;
	margin-right: 1.2%;
	margin-left: 1.2%;
}

.drop2{
	color: black;
	width: 45%;
	height: 40px;
	font-size: 1.3em;
	margin-bottom: 20px;
	margin-left: 3.3%;
}

.drp{
	margin-left: 44%;
}

.drop21{
	color: black;
	width: 40.5%;
	height: 40px;
	font-size: 1.3em;
	margin-bottom: 20px;
	margin-right: 3.3%;
}

.lab{
	padding-left: 10px;
	font-size: 1.5em;
}

.lab2{
	padding-left: 3.3%;
	font-size: 1.5em;
}
.data{
  display: flex;
  justify-content: center;
  position: relative;
}
.categories{
  background-color: #ffffff;
  width: 600px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 1px 15px 1px #7a7a7a;
  display: flex;
  justify-content: space-evenly;
  z-index: 2;
}
.categories span{
  font-size: 14px;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fas{
  font-size: 24px;
  margin-bottom: 5px;
}
.tickets{
  background-color: #ffffff;
  width:900px;
  height: 150px;
  position: absolute;
  top:70px;
  z-index: 1;
  border-radius: 10px;
  padding: 10px;
}
.ticket-type{
  margin-top: 34px;
}
.ticket-type span{
  padding: 5px;
}
.hotel-row{
  padding: 10px;
}
.hotel-heading{
  color:#444444;
  padding: 5px;
  text-align: center;
}
.hotel-heading:hover{
  color:#444444;
}
.price-box {
  margin-top: 10px;
  line-height: 28px;
}
.visachecklisticon{
  width: 29px;
}
.packagesdealclass{
  width: 29px;
}
.checklistclass{
  color:#444444;
}
.pacjageclass{
  color:#444444;
}
.checklistclass:hover{
  color:#444444;
}
.pacjageclass:hover{
  color:#444444;
}

.courses .course-item {
  border-radius: 5px;
  border: 1px solid #eef0ef;
}
.courses .course-content {
  padding: 15px;
}
.courses .course-content h3 {
  font-weight: 700;
  font-size: 20px;
}
.courses .course-content h3 a {
  color: #37423b;
  transition: 0.3s;
}
.courses .course-content p {
  font-size: 14px;
  color: #777777;
}
.courses .course-content h4 {
  font-size: 14px;
  background: #133957;
  padding: 7px 14px;
  color: #fff;
  margin: 0;
  cursor: pointer;
}
.courses .course-content .price {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: #37423b;
}
.hotelclass{
  padding: 10px!important;
}


.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.aside-block {
  margin-bottom: 30px;
}
.aside-title, .category-title {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 30px;
  border-bottom: 2px solid var(--color-black);
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.aside-links li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--color-black-rgb), 0.1);
}
.aside-links li a {
  display: block;
  color: rgba(var(--color-black-rgb), 0.7);
}
.fade:not(.show) {
  opacity: 1;
}
.address {
  padding-left: 10px;
  color: #333;
  display: inline-block;
  margin: 0;
  font-family: 'Open sans';
}

.block_box {
  background: #fff;
  border-radius: 0px;
  border: 1px solid #e5e7f2;
}
.box-widget-item, .list-single-facts {
  margin-bottom: 20px;
}
.box-widget-item-header {
  position: relative;
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 18px 30px;
  /* color: white; */
  border-radius: 10px 10px 0 0;
}
.box-widget-item-header>h3 {
  color: #000 !important;
  font-size: 17px;
  text-transform: uppercase;
  font-family: 'Raleway';
}
#enquiry_form {
  padding: 20px;
}
.span_data{
  font-weight: 700;
}

#footer .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.iata_image{
  margin-left: 89%;
  margin-top: -37%;
}
.search_row{
  margin-top: -15%;
}

@media (min-width:320px)  { 
  .iata_image{
    margin-left: 50%;
      margin-top: -61%;
  }
}
@media (min-width:481px)  { 
    .iata_image {
      margin-left: 50%;
      margin-top: -53%;
    }
}
@media (min-width:641px)  { 
  .iata_image{
    margin-left: 77%;
    margin-top: -47%;
  }
}
@media (min-width:961px)  { 
  .iata_image{
    margin-left: 87%;
    margin-top: -34%;
  }
}

.badge {
  position: absolute;
    top: 62px;
    color: #000;
    left:15.5%;
}
.contact_error{
  color:red;
}

/*EVISA*/
.HeroSection_heroContainer__fecG2{
  min-height: 50vh;
  background-color: #f2f5f6;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  margin-top: 112px;
}
.newevisaclass{
  margin-top: 25px;
}
.HeroSection_welcomeText__tCxvZ{
  font-size: 3rem;
}
.HeroSection_fontChange__CEeC6{
  font-size: 3.5rem;
}
.evisah6{
  font-size: 1.25rem;
  line-height: 30px;
  margin-bottom: 0.35em;
  font-family: inherit;
  font-weight: 400;
}
.evisah6 span{
  color: #f65c19;
}
.evisah3{
  font-size: 3rem;
  line-height: 1.167;
  margin-bottom: 0.35em;
  font-family: inherit;
  font-weight: 600;
}
.HeroSection_stepsContainer__M0r33{
  display: flex;
  flex-direction: row;
}
.HeroSection_step__7tWVV{
  background-color: #133957;
  color: #ffffff;
  min-width: 145px;
  height: 50px;
  width: 145px;
  padding: 5px 10px;
  margin: 15px 0;
  position: relative;
}
.css-1ywsn9k {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  margin-bottom: 0.35em;
  font-family: inherit;
  margin: 0px;
  padding: 0px;
  font-weight: 600;
  font-size: 14px;
}
.css-1hqei1c {
  margin: 0;
  font-family: PT Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  margin-bottom: 0.35em;
  font-family: inherit;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
}
.HeroSection_step1__Cifs4 {
  background-color: #133957;
}
.HeroSection_step1__Cifs4:after{
  content: "";
    position: absolute;
    right: -10px;
    top: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 11px solid #133957;
}
.HeroSection_step2__P_4cY {
  margin: 15px 20px;
  background-color: #133957;
}
.HeroSection_step2__P_4cY:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  border-top: 25px solid #133957;
  border-bottom: 25px solid #133957;
  border-left: 11px solid transparent;
}
.HeroSection_step2__P_4cY:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 11px solid #133957;
}
.HeroSection_step3__c0686:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  border-top: 25px solid #133957;
  border-bottom: 25px solid #133957;
  border-left: 11px solid transparent;
}
.HeroSection_heroContainer__fecG2 .HeroSection_appBtn__4S0uJ {
    height: 42px;
    border-radius: 10px;
    background-color: #f65c19;
    color: #ffffff;
    padding: 10px 42px;
    width: 200px;
    text-align: center;
    margin: 15px 0;
}
.VisaSection_visaContainer__lpJPw{
  min-height: 60vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
}
.css-mkg29l{
  margin: 0;
    font-family: PT Sans, sans-serif;
    font-weight: 19;
    font-size: 3rem;
    line-height: 1.167;
    margin-bottom: 0.35em;
    font-family: inherit;
    text-align: center;
    margin-top: 32px;
    margin-left: 32px;
    margin-right: 32px;
    font-weight: 600;
}
.css-1u4yx72{
  margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.00938em;
    line-height: 1.5;
    margin-bottom: 0.35em;
    font-family: inherit;
    text-align: center;
    margin-left: 16px;
    margin-right: 16px;
}
.VisaSection_visaContainer__lpJPw img {
  display: inline-block;
  margin: -2px 5px 0;
  width: 22px;
  height: 22px;
}
.VisaSection_mainVisaContainer__KUODU {
  width: 100%;
}
.VisaSection_mainVisaContainer__KUODU .VisaSection_tabList__Zc6wR {
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  font-size: 18px;
  margin: 15px auto auto;
  border: 1px solid #cdcdcd;
  background-color: #d6dce3;
  border-radius: 20px;
  width: -moz-max-content;
  width: max-content;
  padding: 5px;
}
.VisaSection_taebSwitch__SuGOs {
  position: relative;
  display: flex;
  flex-direction: row;
}
.VisaSection_mainVisaContainer__KUODU .VisaSection_activeTabs__yyuMd {
  color: #133957;
  font-weight: 600;
  position: absolute;
  width: 50%;
  top: 0;
  transition: left .6s cubic-bezier(.88,-.35,.565,1.35);
  border-radius: 15px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
  background-color: #ffffff;
  height: 100%;
  z-index: 0;
}
.VisaSection_mainVisaContainer__KUODU .VisaSection_tabs__fjRzK {
  font-size: medium;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  min-height: 45px;
  text-align: center;
  min-width: 150px;
  font-weight: 400;
  color: rgba(0, 0, 0, .8);
}
.VisaSection_ModalClosed__HXUL0:after {
  animation: VisaSection_closeModal__wsf3L .4s ease-out forwards;
}
.VisaSection_taebSwitch__SuGOs li {
  width: 100px;
  margin: auto;
  z-index: 1;
}
.VisaSection_tabs__fjRzK1{
  position: absolute;
  width: 50%;
  top: 0;
  transition: left .6s cubic-bezier(.88,-.35,.565,1.35);
  border-radius: 15px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
  background-color: #ffffff;
  height: 100%;
  z-index: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.VisaCardV2_switch__heP_y {
  position: absolute;
  display: inline-block;
  margin: 0 5px;
  z-index: 3;
  top: 7%;
  right: 10px;
}
.VisaCardV2_switch__heP_y input {
  display: none;
}
.VisaCardV2_switch__heP_y small {
  display: inline-block;
  width: 85px;
  height: 31px;
  background: rgba(69, 90, 100, .396);
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}
.VisaCardV2_switch__heP_y small:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: .3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, .1);
}
.VisaCardV2_switch__heP_y small:after {
  content: "Regular";
  position: absolute;
  color: #fff;
  width: 100%;
  left: 0;
  text-align: right;
  padding: 0 8px;
  box-sizing: border-box;
  line-height: 31px;
  font-size: 12px;
}
.VisaCardV2_switch__heP_y input:checked~small:after {
  content: "Express";
  text-align: left;
  font-size: 12px;
}
.VisaCardV2_visaContentContainer__ng1uG {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  margin: 15px 0;
}
.VisaCardV2_activeContent__uF18_ {
  display: flex;
  height: 100%;
  z-index: 2;
  align-items: center;
}
.css-122ud6i{
  width: 100%;
    height: 100%;
    border-radius: 15px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    position: relative;
}
.css-qdvnpr {
  margin: 0;
  font-family: PT Sans, sans-serif;
  font-weight: 19;
  font-size: 1.5rem;
  line-height: 1.334;
  margin-bottom: 0.35em;
  font-family: inherit;
  font-weight: 600 !important;
  padding-left: 30px;
}
.css-rn90z {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 0.35em;
  font-family: inherit;
  margin: 0px !important;
  padding-left: 30px;
}
.VisaCardV2_line__mDleZ {
  width: 90%;
  height: 3px;
  margin: 10px auto 20px;
  background-color: #133957;
}
.css-1brr7ct {
  margin: 0;
  font-family: PT Sans, sans-serif;
  font-weight: 19;
  font-size: 3rem;
  line-height: 1.167;
  text-align: center;
  margin-bottom: 0.35em;
  font-family: inherit;
  font-weight: 600 !important;
  width: 100%;
}
.css-1228dmr {
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  font-family: inherit;
  font-weight: 600 !important;
}
.VisaCardV2_activeContent__uF18_ svg {
  margin-right: 5px;
  color: #cedbe9;
  padding: 0 1px;
}
.css-1k33q06 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}
.VisaCardV2_activeContent__uF18_ span {
  display: block;
  font-weight: 200;
  font-size: 14px;
  margin: 0 25px;
  color: #133957;
}
.VisaCardV2_activeContent__uF18_ Button {
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(90deg, #e85f20, #ff8a52);
  width: 90%;
  margin: 20px auto 10px;
  color: #fff;
}
.css-kurovt {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: PT Sans, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #133957;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: white;
  font-family: inherit;
}
/*END EVISA*/

/*Apply*/

.pageSectionsContainer{
  min-height: 50vh;
  margin-top: 74px;
}
.ApplyFormSection_sectionContainer__kYPQD{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.css-14ne836 {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  max-width: 1200px;
}
.ApplyFormSection_sectionContainer__kYPQD .ApplyFormSection_card__8wu6W {
  width: 100%;
  padding: 20px;
  margin: auto;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .33);
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, .33);
  -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, .33);
}
.css-wz2b58 {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.57) 2px 3px 5px 0px;
  overflow: hidden;
}
.FormSteper_customSteperStyle__45AOL {
  margin: 10px 0;
}
.css-ogvvg2 {
  display: flex;
  align-items: flex-start;
  flex-direction: unset;
}
.css-166ciyp {
  padding-left: 8px;
  padding-right: 8px;
  flex: 1 1 0%;
  position: relative;
}
.css-1abj2s5.MuiStepLabel-alternativeLabel {
  flex-direction: column;
}
.css-1abj2s5 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-a5nipc.MuiStepLabel-alternativeLabel {
  padding-right: 0px;
}
.css-a5nipc {
  flex-shrink: 0;
  display: flex;
  padding-right: 8px;
}
.css-uxwysf {
  z-index: 1;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(246, 92, 25);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px 0px;
}
.css-vubbuv {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.css-uulo2i.MuiStepConnector-alternativeLabel {
  top: 20px;
}
.css-uulo2i {
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
}
.css-uulo2i .MuiStepConnector-line {
  height: 2px;
  border: 0px;
  background-color: rgb(234, 234, 240);
  border-radius: 1px;
}
.css-95m0ql {
  display: block;
  border-color: rgb(189, 189, 189);
  border-top-style: solid;
  border-top-width: 1px;
}
.css-1abj2s5.Mui-disabled {
  cursor: default;
}
.css-1abj2s5.MuiStepLabel-alternativeLabel {
  flex-direction: column;
}
.css-1abj2s5 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-a5nipc.MuiStepLabel-alternativeLabel {
  padding-right: 0px;
}
.css-a5nipc {
  flex-shrink: 0;
  display: flex;
  padding-right: 8px;
}

.css-bq6l67 {
  background-color: rgb(204, 204, 204);
  z-index: 1;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.css-vubbuv {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.css-h2cmlr.MuiStepLabel-alternativeLabel {
  text-align: center;
}
.css-h2cmlr {
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
}
.css-ek8oaw.MuiStepLabel-alternativeLabel {
  margin-top: 16px;
}
.css-ek8oaw {
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  display: block;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.css-171ap4h {
  margin: 0px 0px 0.35em;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  font-family: inherit;
}
.ApplyFormSection_sectionContainer__kYPQD .ApplyFormSection_heading___4xHr {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.ApplyFormSection_sectionContainer__kYPQD .ApplyFormSection_heading___4xHr h5 {
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #133957;
}
.css-3m9515 {
  margin: 0px 0px 0.35em;
  font-weight: 19;
  font-size: 1.5rem;
  line-height: 1.334;
  font-family: inherit;
}
.ApplyFormSection_sectionContainer__kYPQD .ApplyFormSection_heading___4xHr img {
  min-width: 30px;
  max-height: 20px;
}
.ApplyFormContent_formConatainer__jDsqK {
  padding: 40px 20px !important;
}
.css-isbt42 {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
}
.css-isbt42 > .MuiGrid-item {
  padding-left: 16px;
}
.css-isbt42 > .MuiGrid-item {
  padding-top: 16px;
}
@media (min-width: 1200px) {
      .css-15j76c0 {
          flex-basis: 100%;
          -webkit-box-flex: 0;
          flex-grow: 0;
          max-width: 100%;
      }
  }
  .css-15j76c0 {
    box-sizing: border-box;
    margin: 0px;
    flex-direction: row;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
}
.ApplyFormContent_formConatainer__jDsqK h5 {
  font-weight: 700;
  color: #133957;
}
.row{
  width: 100%;
}
.css-1wrz314 {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  font-family: inherit;
  display: inline-block;
  padding: 10px;
}
.css-isbt42 > .MuiGrid-item {
  padding-left: 16px;
}
.css-isbt42 > .MuiGrid-item {
  padding-top: 16px;
}
.css-1ul47bz {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin: 0px;
}
@media (min-width: 900px) {
      .css-1weff7v {
          padding: 10px !important;
          margin-bottom: 0px !important;
      }
  }
  @media (min-width: 1200px) {
  
        .css-1weff7v {
            flex-basis: 25%;
            -webkit-box-flex: 0;
            flex-grow: 0;
            max-width: 25%;
        }
    }
    .css-1weff7v {
      box-sizing: border-box;
      margin: 0px;
      flex-direction: row;
      flex-basis: 100%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 30%;
  }
  button, input, optgroup, select, textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
.FileUploadV2_uploadField__JJVi0 .FileUploadV2_uploadFieldContent__WZZ9I {
  width: 65%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  min-height: 150px;
}
.FileUploadV2_uploadField__JJVi0 .FileUploadV2_uploadFieldContent__WZZ9I .FileUploadV2_fileUploadIcon__AB0x1 {
  padding: 10px 10px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.FileUploadV2_uploadField__JJVi0 .FileUploadV2_uploadFieldContent__WZZ9I .FileUploadV2_fileUploadIcon__AB0x1 svg {
  background: #133957;
  color: #ffffff;
  border-radius: 12px;
  padding: 2px;
}
.FileUploadV2_uploadField__JJVi0 .FileUploadV2_uploadFieldContent__WZZ9I .FileUploadV2_documentIcon__aUO8N {
  padding: 10px 10px 15px;
  justify-content: center;
  display: flex;
  margin: auto;
  opacity: .7;
}
.css-13bc3to {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(19, 57, 87);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 100%;
  color: white;
  font-family: inherit;
}
.FileUploadV2_uploadField__JJVi0 .FileUploadV2_uploadFieldContent__WZZ9I .FileUploadV2_textStyle__8C2It {
  text-align: center;
  background: #133957;
  padding: 5px;
  color: #ffffff;
  border-radius: 0 0 10px 10px;
}
.FileUploadV2_uploadField__JJVi0 .FileUploadV2_uploadFieldContent__WZZ9I .FileUploadV2_textStyle__8C2It div {
  position: absolute;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  padding: 5px;
}
.css-vubbuv {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.css-1ruiwe2 {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  font-family: inherit;
}
.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}
@media (min-width: 1200px) {
      .css-15j76c0 {
          flex-basis: 100%;
          -webkit-box-flex: 0;
          flex-grow: 0;
          max-width: 100%;
      }
  }
.ApplyFormContent_formConatainer__jDsqK .ApplyFormContent_termsCondition__jNRX4 label {
    display: flex;
    align-items: center;
}
.css-uboc2l {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  padding-top: 16px;
  margin-left: auto;
    margin-right: auto
}
.ApplyFormContent_formConatainer__jDsqK .ApplyFormContent_formSubmitButton__7YkKM Button {
  background: #f65c19;
  min-width: 178px;
}
.ApplyFormContent_formConatainer__jDsqK .ApplyFormContent_formSubmitButton__7YkKM Button span {
  font-weight: 700;
  margin-left: 5px;
}
.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}
.newclass{
  padding:10px;
}
.VisaCardV2_switch__heP_y input:checked~small {
  background: #31b63a;
  transition: .3s;
}
.VisaCardV2_switch__heP_y input:checked~small:before {
  transform: translate(54px);
  transition: .3s;
}
.VisaCardV2_switch__heP_y small:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: .3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, .1);
}
.VisaCardV2_switch__heP_y input:checked~small:after {
  content: "Express";
  text-align: left;
  font-size: 12px;
}
.sent-message {
  color: green;
  font-size: 16px;
}

.loading {
  font-size: 16px;
}

.modify-search .head-wrapper {
  min-height: auto;
}

.head-wrapper {
  display: block;
  min-height: 80vh;
  background-size: cover;
  position: relative;
  z-index: 2;
}
.head-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  background: #343534;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.modify-search .flight-search {
  background-color: #002d5b;
  margin-top: 8%;
}
.flight-search {
  position: relative;
  z-index: 0;
  background: rgba(255, 255, 255, 0.5);
  padding: 1.5rem 0;
}

.border-0 {
  border: 0 !important;
}

.modify-search .flight-search .nav-tabs .nav-item .nav-link {
  color: #ffffff;
}

.flight-search .nav-tabs .nav-item .nav-link {
  font-weight: 700;
  color: #002d5b;
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem 1.5rem 0rem;
}
.flight-search .nav-tabs .nav-item .active span:after {
  content: "";
  display: block;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #ffc104;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
}
.flight-search .nav-tabs .nav-item .active span {
  background: #ffffff !important;
  border: 1px solid #ffc104;
  position: relative;
}
.flight-search .icon-20 {
  width: 20px;
  height: 20px;
}
.flight-search .search-pan {
  background: #ffffff;
  padding: 0.8rem;
  min-height: auto;
}
.theme-border-radius {
  border-radius: 0.5rem;
}
.flight-search .search-pan .form-group {
  position: relative;
}
.flight-search .search-pan .icon-pos {
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}
.flight-search .search-pan .form-control {
  font-size: 14px;
  background-color: #f5faff;
  min-height: 56px;
  border: 0px;
  text-decoration: none;
  border-radius: 0.5rem;
  text-align: left;
}
.flight-search .pos-swap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  position: absolute;
  top: 0.9rem;
  right: -1.2rem;
  z-index: 1;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}
.flight-search .search-pan .icon-pos {
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}
.flight-search .search-pan .dep-date-input {
  position: relative;
  width: auto;
  height: 2rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background: #ffffff;
  border-style: none;
  padding: 0 0;
  margin: 0.3rem 0 0 2rem;
}
.flight-search .search-pan .cal-input {
  background: none;
  border: 0px;
  width: 6rem;
  padding: 0.4rem 0 0 0.5rem;
}
.flight-search .search-pan .dep-date-input:after {
  content: "";
  position: absolute;
  right: -12px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 0.8rem solid #ffffff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}
.flight-search .search-pan .icon-pos {
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}
.flight-search .search-pan .form-control {
  font-size: 14px;
  background-color: #f5faff;
  min-height: 56px;
  border: 0px;
  text-decoration: none;
  border-radius: 0.5rem;
  text-align: left;
}
.flight-search .search-pan .dropdown-toggle {
  padding: 0 1rem 0 3rem;
}
.flight-search .search-pan .form-control {
  font-size: 14px;
  background-color: #f5faff;
  min-height: 56px;
  border: 0px;
  text-decoration: none;
  border-radius: 0.5rem;
  text-align: left;
}
.flight-search .search-pan .dropdown-toggle::before {
  color: #9f9f9f;
  right: 0.8rem;
}
.dropdown-menu::before, .dropdown-toggle::before {
  content: "";
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border: 5px solid;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  margin-top: -2.5px;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
  z-index: 10;
}
.flight-search .search-pan .icon-pos {
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flight-search .search-pan .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 50%;
}
.flight-search .search-pan .dropdown .dropdown-menu {
  width: 100%;
  padding: 0px 0.5rem;
  border-radius: 0.5rem;
}
.dropdown-menu {
  z-index: 10;
  position: relative;
}
.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li {
  border-bottom: 1px solid #f5faff;
  display: flex;
  padding: 5px 0 5px 0;
}
.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
  align-items: center;
  width: 45%;
}
.flight-search .search-pan .input-group {
  color: inherit;
}
.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field {
  text-align: center;
  width: 50%;
  background: #f5faff;
  border: 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-button button {
  background-color: #002d5b;
  border: none;
  color: #ffffff;
}
.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .btn {
  background-color: #002d5b;
  color: #ffffff;
  border: 0;
}

.btn::before, .btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.btn::before, .btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.flight-search .btn-search {
  background-color: #002d5b;
  min-height: 56px;
  color: #ffffff;
  min-width: 75px;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.flight-search .btn-search:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: -100%;
  background: #ffc104;
  transition: all 0.2s;
}
.btn::before, .btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.modify-search .flight-search .check-wrap {
  color: #ffffff;
}
.flight-search .check-wrap {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #343534;
}
.check-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #343534;
  font-size: 0.75rem;
}
.flight-search .check-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.modify-search .flight-search .check-wrap .checkmark {
  border: 1px solid #ffc104;
}
.flight-search .check-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 0.2rem;
  border: 1px solid #343534;
}
.tab-content .active {
  background-color:#002d5b!important;
}
.flight-search .search-pan {
  background: #ffffff;
  padding: 0.8rem;
  min-height: auto;
}
.filter-sec {
  background-color: #f5faff;
}
.filter-sec .flt-btn {
  position: relative;
  padding: 0 2rem 0 0;
}
.filter-sec .flt-btn::before {
  content: "\f282";
  font-family: bootstrap-icons !important;
  display: inline-block;
  background-color: transparent;
  width: 20px;
  height: 20px;
  z-index: 1;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
}
.btn::before, .btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.content-section{
  background-color: #efeeee;
}
.theme-bg-accent-three {
  background-color: #f5faff;
}
.theme-border-radius {
  border-radius: 0.5rem;
}
.font-small {
  font-size: 0.75rem;
}
.theme-box-shadow {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}
.theme-bg-white {
  background-color: #ffffff;
}

.btn-select {
  position: relative;
  border: 1px solid #002d5b;
  color: #002d5b;
  z-index: 1;
}

.btn::before, .btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.btn-effect::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}
.btn::before, .btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}


.shimmer-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 10px;
  background: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%; /* Full width */
  position: relative;
}

.shimmer {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 300% 100%; /* Wider gradient for smoother motion */
  animation: shimmer 1.8s infinite ease-in-out;
  width: 100%; /* Full width shimmer */
}

.shimmer-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.shimmer-rectangle {
  width: 100%; /* Full width */
  height: 16px;
  border-radius: 4px;
  margin-bottom: 6px;
}

.shimmer-text {
  width: 100%; /* Full width */
  height: 12px;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -300% 0; /* Start from far left */
  }
  100% {
    background-position: 300% 0; /* End at far right */
  }
}
.flight-search {
  position: relative;
  z-index: 0;
  background: rgba(255, 255, 255, 0.5);
  padding: 1.5rem 0;
}
.head-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  background: #343534;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.modify-search .flight-search {
  background-color: #002d5b;
}
.reviewStatus {
  width: 100%;
  display: flex;
  height: 2px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  color: #ffffff;
  font-size: 0.75rem;
}
.theme-text-white {
  color: #ffffff;
}
.reviewStatus.step2:before {
  width: 25%;
  animation-name: step2;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

.reviewStatus:before {
  height: 2px;
  background: #ffc104;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
}
.reviewStatus li {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.reviewStatus .numbering.completed {
  background: #ffc104;
  text-indent: -999999px;
  position: relative;
}
.reviewStatus .numbering {
  width: 13px;
  min-height: 13px;
  font-size: 10px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: -5px;
  color: #343534;
}
.reviewStatus .numbering.onpage {
  background: #ffc104;
  color: #ffffff;
}
.reviewStatus .reviewText.active {
  font-weight: 700;
  color: #ffffff;
  background: none;
}
.theme-border-radius {
  border-radius: 0.5rem;
}
.theme-box-shadow {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}
.theme-text-white {
  color: #ffffff;
}
.theme-bg-secondary {
  background-color: #002d5b;
}
.font-medium {
  font-size: 0.875rem;
}
.font-small {
  font-size: 0.75rem;
}
.theme-border-radius {
  border-radius: 0.5rem;
}
.btn-book:hover {
  border-color: inherit;
  background-color: #002d5b;
  overflow: hidden;
  color: #ffffff;
}
.btn-book {
  position: relative;
  background-color: #002d5b;
  color: #ffffff;
  z-index: 0;
  overflow: hidden;
}
.theme-text-accent-one {
  color: #343534;
}